<template>
  <div class="mainform">
    <div class="mainHeader mag-btm">
        发布询价
        <span @click="back" class="back">返回></span>
      </div>
    <div class="form fbxj-table">
      <el-form class="content" :rules="rules" ref="form" :model="form" label-width="120px">
        <el-form-item label="询价描述" prop="inquiryDesc">
          <el-input type="textarea" v-model="form.inquiryDesc" placeholder="请输入询价描述"></el-input>
        </el-form-item>
        <el-form-item label="询价类型" prop="inquiryType">
          <el-radio-group v-model="form.inquiryType" @change="choiceChange">
            <el-radio label="1">单一供应商询价</el-radio>
            <el-radio label="2">多家供应商询价</el-radio>
            <el-radio label="3">普通竞价</el-radio>
            <el-radio label="4">反向竞价</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="询价模式" v-if="showPattern" prop="inquirySchema">
          <el-radio-group v-model="form.inquirySchema">
            <el-radio label="1">公开身份公开报价</el-radio>
            <el-radio label="2">公开身份隐藏报价</el-radio>
            <el-radio label="3">隐藏身份公开报价</el-radio>
            <el-radio label="4">隐藏身份隐藏报价</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="bidding" label="竞价方式" v-if="showPattern" prop="biddingWay">
          <el-radio-group v-model="form.biddingWay" @change="choiceMode">
            <el-radio label="1">单轮竞价</el-radio>
            <el-radio label="2">多轮竞价</el-radio>
          </el-radio-group>
          <el-form-item class="bidding-ipn" v-if="showMode" prop="biddingBz">
            <el-input v-model.number="form.biddingBz" placeholder="请输入多轮竞价次数"></el-input>
          </el-form-item>
        </el-form-item>
        <el-form-item label="经营范围" prop="businessScope">
          <el-col :span="13">
            <el-select @change="ScopeChange" v-model="form.businessScope">
              <el-option :key="index" v-for="(itme,index) in businessScopeList" :label="itme.companyDesc" :value="itme.businessScope"></el-option>
            </el-select>
          </el-col>
          <el-col :span="13" style="margin-left: 20px;">
            <el-form-item prop="companyCode">
              <el-select @change="companyChange" v-model="form.companyCode">
                <el-option :key="index" v-for="(itme,index) in companyCodeList" :label="itme.companyCode" :value="itme.companyCode"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="明细项" prop="inquiryMaterialMap">
          <el-upload
            ref="upload"
            name="file"
            :auto-upload="true"
            :action= 'postUrl'
            :show-file-list="false"
            :on-exceed="handleExceed"
            :on-change="handleChansge"
            :on-success="handSuccess"
            :on-error="handError"
            :headers="tokenHeader"
          >
            <el-button size="small" type="primary" plain>上传excel</el-button>
          </el-upload>
          <div v-if="issueShow">
            <div class="tableList">
              <div class="table">
                <el-table stripe :data="form.inquiryMaterialMap.data" style="width: 100%">
                  <el-table-column v-for="(item) in form.inquiryMaterialMap.title" :key='item' :prop='item' :label='item'></el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </el-form-item>
         <el-form-item label="询价文件" prop="fileInfoList">
            <el-upload
                :action="actionURL"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :headers="tokenHeader"
                :on-success="handleSuccess"
                multiple
                :on-exceed="handleExceed"
                :file-list="form.fileInfoList"
            >
                <el-button size="small" type="primary" plain>点击上传</el-button>
            </el-upload>
         </el-form-item>
         <el-form-item label="显示报价" prop="isShowInquiry">
          <el-checkbox v-model="form.isShowInquiry"></el-checkbox>
        </el-form-item>
        <el-form-item label="报价指标" prop="targetList">
          <el-select v-model="form.targetList" multiple filterable allow-create
          default-first-option
          placeholder="请选择指标">
            <el-option
              v-for="(item) in quotaOptions"
              :key="item.id"
              :label="item.detailsValue"
              :value="item.detailsValue">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报价截止时间">
          <el-date-picker v-model="form.inquiryEndTime" @change="timeForm" type="datetime" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <div>
          <inviting-suppliers ref="suppliers" :dataFormat="rules.invitingSuppliers" :formData="form" @dataAction="dataAction" class="content"></inviting-suppliers>
        </div>
        <div class="action ">
          <el-button class="preservation" type="primary" size="medium" @click="preservation">保存</el-button>
          <el-button class="release" type="primary" size="medium" @click="release">发布</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { baseHost } from '@/assets/js/PublicData.js'
import { baseURL, request, deepClone } from '@/assets/js/http.js'
import { formData, rules } from './js/setting.js'
import dayjs from 'dayjs'
import { FlowCtlData } from '@/assets/js/FlowManage.js'
import Component from '@/assets/js/components.js'
import InvitingSuppliers from '../../module/InvitingSuppliers.vue'
export default {
  components: { ...Component.components, InvitingSuppliers },
  name: 'IssueInquiry',
  props: [],
  data: function () {
    FlowCtlData.docid = ''
    return {
        actionURL: baseHost.SupplierRegHost + '/api/file/m/file/uploadToServer',
      tokenHeader: {
        token: localStorage.getItem('token')
      },
      postUrl: baseURL + '/api/bid/upload/material',
      showPattern: false,
      showMode: false,
      issueShow: false,
      businessScopeList: [],
      companyCodeList: [],
      form: deepClone(formData),
      quotaOptions: [],
      rules: rules
    }
  },
  watch: {
  },
  created () {
    var id = this.$route.query.id
    FlowCtlData.flowid = 'issueinquiry'
    FlowCtlData.docid = id
    FlowCtlData.initialize()
    this.editQuotation()
  },
  mounted () {
    request('/api/sap/businessScope/getBusinessScopeList', 'get').then((response) => {
      this.businessScopeList = response
    })
    request('/api/system/dictDetails/getByGroupCode?groupCode=INQUIRY_STANDARD', 'get').then((response) => {
      this.quotaOptions = response
    })
  },
  methods: {
    handleRemove (file) {
      for (var i = 0; i < this.form.fileInfoList.length; i++) {
        if (this.form.fileInfoList[i].id === file.id) {
          this.form.fileInfoList.splice(i, 1)
        }
      }
    },
    handlePreview (file) {
        console.log('file', file)
      window.open(baseURL + '/api/file/m/file/downloadFile/' + file.id + '?type=1')
    },
     beforeRemove (file, fileInfoList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleSuccess (response, file, fileInfoList) {
      if (response.code === '200') {
        response.data.forEach(element => {
            console.log('element', element)
          this.form.fileInfoList.push(element)
        })
      }
    },
    // 编辑询报价
    editQuotation () {
      const id = this.$route.query.id
      if (id) {
        request('/api/inquiry/getById/' + id, 'get').then((res) => {
          if (res.code === '200') {
            this.issueShow = true
            this.form = res.data
            if (res.data.inquiryType === 3) {
              this.showPattern = true
            }
            if (res.data.biddingWay === 2) {
              this.showMode = true
            }
            this.form.inquiryType = res.data.inquiryType.toString()
            if (res.data.inquirySchema) {
              this.form.inquirySchema = res.data.inquirySchema.toString()
            }
            this.form.isShowInquiry = this.form.isShowInquiry == 1 ? true : false
            if (res.data.biddingWay) {
              this.form.biddingWay = res.data.biddingWay.toString()
            }
          }
        })
      }
    },
    // 保存
    preservation () {
      this.form.titleList = []
      for (var i of this.form.targetList) {
        for (var j of this.quotaOptions) {
          if (i === j.detailsValue) {
            this.form.titleList.push(j)
          }
        }
      }
      const obj = {
        ...this.form,
        companyName: this.form.companyName,
        status: 0,
        isShowInquiry:this.form.isShowInquiry ? '1' : '2'
      }
      request('/api/inquiry/save', 'post', obj).then((response) => {
        if (response.code === '200') {
          this.$message({
            showClose: true,
            message: '保存成功',
            type: 'success'
          })
          this.$router.push({
            path: '/Invitation/InquiryAndQuotation'
          })
        }
      })
    },
    // 发布
    async release () {
      var isPass = true
      this.$refs.form.validate((valid) => {
        if (!valid) {
          isPass = false
        }
      })
      this.$refs.suppliers.$refs.choiceSupplier.validate((valid) => {
        if (!valid) {
          isPass = false
        }
      })
      if (!isPass) {
        this.$message({
          message: '请将信息填写完整',
          type: 'error'
        })
        return false
      }
      this.form.titleList = []
      for (var i of this.form.targetList) {
        for (var j of this.quotaOptions) {
          if (i === j.detailsValue) {
            this.form.titleList.push(j)
          }
        }
      }
      
      const obj = {
        ...this.form,
        companyName: this.form.companyName,
        status: 1,
        isShowInquiry:this.form.isShowInquiry ? '1' : '2'
      }
      this.form.contact = ''
      const SupplierIds = []
      for (const item of this.form.inquirySupplierList) {
        SupplierIds.push(item.code)
      }
      await request('/api/system/user/getUserListBySupplierId', 'post', SupplierIds).then((response) => {
        if (response.code === '200') {
          for (const item of response.data) {
            this.form.contact = this.form.contact + item.username + ';'
          }
        }
      })
      FlowCtlData.getNodeAction('TJ', this.form).then(
        (val) => {
          if (val) {
            FlowCtlData.alertDialog(this).then(() => {
              FlowCtlData.setFormData(FlowCtlData, obj)
              console.log('obj', obj)
              request('/api/inquiry/save', 'post', obj).then((response) => {
                if (response.code === '200') {
                  FlowCtlData.docid = response.data
                  FlowCtlData.setNodeAction().then(
                    () => {
                      this.$message({
                        showClose: true,
                        message: '发布成功',
                        type: 'success'
                      })
                      this.$router.push({
                        path: '/Invitation/InquiryAndQuotation'
                      })
                    }
                  ).catch(() => {
                    this.$message({
                      showClose: true,
                      message: '发布失败',
                      type: 'error'
                    })
                  })
                }
              })
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消提交'
              })
            })
          }
        }
      )
    },
    // 选择报价截止日期转换格式
    timeForm (val) {
      if (this.form.inquiryEndTime) {
        this.form.inquiryEndTime = dayjs(this.form.inquiryEndTime).format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.form.inquiryEndTime = ''
      }
    },
    // 邀请供应商
    dataAction: function (data) {
    },
    // 经营范围
    ScopeChange: function () {
      this.form.companyCode = ''
      if (this.form.businessScope !== '') {
        request('/api/sap/getCompanyCodeName/' + this.form.businessScope, 'get').then((response) => {
          this.companyCodeList = response
        })
      }
    },
    companyChange: function (data) {
      for (var item of this.companyCodeList) {
        if (item.companyCode === data) {
          this.form.companyName = item.companyName
        }
      }
    },
    // 选择普通竞价时显示竞价模式和竞价方式
    choiceChange (val) {
      if (val === '3') {
        this.showPattern = true
      } else {
        this.showPattern = false
        this.form.inquirySchema = ''
        this.form.biddingWay = ''
        this.showMode = false
        this.form.biddingBz = ''
      }
    },
    // 选择多轮竞价时显示输入框
    choiceMode (val) {
      if (val === '2') {
        this.showMode = true
      } else {
        this.showMode = false
        this.form.biddingBz = ''
      }
    },
    // 判断是否只能上传一个文件，超过提示报错
    handleExceed (e) {
      this.$notify.error({
        title: '错误',
        message: '只能上传一个文件'
      })
    },
    // 验证上传文件格式
    handleChansge (file, fileList) {
      if (!/\.(xlsx|xls|XLSX|XLS)$/.test(file.name)) {
        this.$notify.error({
          title: '错误',
          message: '上传文件只能为excel文件，且为xlsx,xls格式'
        })
        return false
      }
    },
    // 上传失败提示
    handError () {
      this.$notify.error({
        title: '错误',
        message: '文件上传失败'
      })
    },
    // 上传成功或错误提示
    handSuccess (response, file, fileList) {
      if (response.code === '200') {
        this.form.inquiryMaterialMap = {}
        this.form.inquiryMaterialMap.data = response.data.data
        this.form.inquiryMaterialMap.title = response.data.title
        this.issueShow = true
      }
    },
    // 返回
    back: () => {
      window.history.back()
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elform.scss';
  @import '@/assets/css/tableList.scss';
  .mag-btm{
    margin-bottom: 20px;
  }
  .fbxj-table .tableList{
    padding: 0;
    margin-top: 20px;
  }
  .fbxj-table .el-select{
    width: 381px;
  }
  // .fbxj-table .el-form-item{
  //   margin-bottom: 20px;
  // }
   .bidding-ipn{
    width: 200px;
    display: inline-block;
    margin-left: 20px;
    margin-bottom: 0px !important;
  }


</style>
