export var formData = {
  inquiryDesc: '',
  inquiryType: '',
  inquirySchema: '',
  biddingWay: '',
  businessScope: '',
  companyCode: '',
  companyName: '',
  company: '',
  biddingBz: '',
  inquiryEndTime: '',
  inquirySupplierList: [],
  inquiryMaterialMap: '',
  targetList: [],
  titleList: [],
  fileInfoList:[],
}

export var rules = {
  inquiryDesc: [
    { required: true, message: '请输入询价描述', trigger: 'blur' }
  ],
  inquiryType: [
    { required: true, message: '请选择询价类型', trigger: 'blur' }
  ],
  inquirySchema: [
    { required: true, message: '请选择询价模式', trigger: 'blur' }
  ],
  biddingWay: [
    { required: true, message: '请选择竞价方式', trigger: 'blur' }
  ],
  companyCode: [
    { required: true, message: '请选择公司代码', trigger: 'change' }
  ],
  inquiryMaterialMap: [
    { required: true, message: '请上传明细项', trigger: 'blur' }
  ],
  biddingBz: [
    { type: 'number', message: '请输入数字' },
    { required: true, message: '请输入多轮竞价次数', trigger: 'blur' }
  ],
  businessScope: [
    { required: true, message: '请选择经营范围', trigger: 'blur' }
  ],
  targetList: [
    { required: true, message: '请选择报价指标', trigger: 'blur' }
  ],
  invitingSuppliers: {
    rules: {
      inquirySupplierList: [
        { required: true, message: '请选择供应商', trigger: 'change' }
      ]
    }
  }
}
